<template>
  <div :class="'news-item translate ' + uniqueClass" v-on:click="open()">
    <div class="content" ref="content">
      <div
        class="photo-section"
        :style="'height:' + photoHeight + 'px;'"
        ref="photoSection"
      >
        <div
          v-if="imageLoaded"
          class="photo"
          :style="'background-image: url(' + data.image_url + ');'"
        ></div>

        <div
          v-else
          class="photo"
          :style="'background-image: url(' + placeholderimg + ');'"
        ></div>
        <div class="info notranslate">
          <div class="tickers">
            <template v-for="(ticker, key) in tickers" :key="key">
              <ion-badge color="light" class="ticker">{{ ticker }}</ion-badge>
            </template>
          </div>
        </div>
      </div>

      <div class="info">
        <p class="title">
          {{ data.title }}
        </p>

        <p class="description">
          {{ description(data.text) }}
        </p>
      </div>

      <div class="meta">
        <div
          v-if="data.news_source_info"
          class="source-img"
          :style="
            'background-image: url(' +
            server +
            '/storage/news-sources/' +
            data.news_source_info.logo +
            ')'
          "
        ></div>
        <div v-else class="source-img source-img-placeholder"></div>
        <div class="source-meta">
          <p class="source-name" v-if="data.news_source_info">
            {{ data.news_source_info.name }}
          </p>
          <p class="source-name" v-else>
            <ion-skeleton-text
              animated="loading"
              style="width: 100px"
            ></ion-skeleton-text>
          </p>
          <p class="time notranslate">{{ $filters.signalTimeLT(data.date, momentJsLocale) }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IonBadge, IonSkeletonText } from "@ionic/vue";
import placeholderimg from "../../../assets/img/placeholder-image.jpg";

export default {
  inject: ["store"],
  props: ["data", "_key", "uniqueClass"],

  components: {
    IonBadge,
    IonSkeletonText
  },

  data() {
    return {
      photoHeight: 0,
      imageLoaded: false,
      placeholderimg,
    };
  },

  computed: {
    server() {
      return this.store.server;
    },

    tickers() {
      return JSON.parse(this.data.tickers);
    },
  },

  methods: {
    open() {
      window.location.href = this.data.news_url;
    },

    wait(millisecs) {
      return new Promise((resolve) => {
        setTimeout(resolve, millisecs);
      });
    },

    setPhotoHeight(contentWidth) {
      this.photoHeight = (contentWidth / 379) * 214;
    },

    description(description) {
      return description.substring(0, 115) + "...";
    },

    setImage() {
      let self = this;
      var highResImage = new Image();

      highResImage.onload = function () {
        self.imageLoaded = true;
      };

      highResImage.onerror = function () {
        setTimeout(self.setImage, 1000);
        console.log(
          "Image load error for signal with id " +
            self.data.id +
            " failed. Rretrying in a sec"
        );
      };

      highResImage.src = self.data.image_url;
    },
  },

  mounted() {
    //Get photo height from client width;
    this.setImage();
    var contentWidth = this.$refs.content.clientWidth;
    this.setPhotoHeight(contentWidth);

    (async () => {
      while (contentWidth <= 0) {
        contentWidth = this.$refs.content.clientWidth;
        this.setPhotoHeight(contentWidth);
        await this.wait(50);
      }
    })();
  },
};
</script>

<style scoped lang="scss">
.news-item {
  background: #fff;
  --padding-start: 0;
  --inner-padding-end: 0;
  margin-bottom: 25px;
  //   box-shadow: 0 7px 51px -49px #aaa;
  border-radius: 20px;
  border-top-left-radius: 30px;
  border: 1px solid #f0f0f0;

  &:hover,
  &:active {
    .photo {
      transform: scale(1.15);
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 395px;

    > div {
      width: 100%;
    }

    .photo-section {
      height: 215px;
      border-top-left-radius: 30px;
      border-top-right-radius: 0px;
      display: flex;
      align-items: flex-end;
      position: relative;
      overflow: hidden;
      // box-shadow: 0 -132px 98px -98px #000 inset;

      .photo {
        height: 100%;
        width: 100%;
        background-size: cover;
        border-top-left-radius: 30px;
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 0.3s;
      }

      .info {
        width: 100%;
        // background: red;
        padding: 16px;

        .tickers {
          //   background: blue;
          width: 100%;
          height: 20px;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .ticker {
            margin-right: 10px;
            background-color: rgba(255, 255, 255, 0.5);
          }
        }

        .title {
          p {
            padding: 0;
            margin: 0;
            padding-top: 5px;
            font-weight: 900;
          }
        }
      }
    }

    .info {
      padding: 0 16px;
      color: #000;
      p.title {
        font-weight: 800;
      }
      p.description {
        font-weight: 400;
        font-size: calc(13px + $extra-font-size);
      }
    }

    .meta {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 16px;
      margin-bottom: 16px;
      box-sizing: border-box;
      color: #000;

      .source-img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-size: cover;
      }

      .source-img-placeholder {
        background: #eee;
      }

      .source-meta {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .source-name {
          font-weight: 700;
          font-size: calc(13px + $extra-font-size);
          color: var(--ion-color-secondary);
        }

        .time {
          color: var(--ion-color-medium-shade);
          font-size: calc(12px + $extra-font-size);
          margin-top: 3px;
        }

        p {
          margin: 0;
        }
      }
    }
  }
}
</style>