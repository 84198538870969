<template>
  <!-- <base-layout :noPageHeader="noPageHeader" :toolbarClass="toolbarClass" :headerClass="headerClass" :pageDefaultBackLink="pageDefaultBackLink"> -->
  <base-layout-2
    :noPageHeader="false"
    :toolbarClass="'home'"
    :toolbarColor="'primary'"
    :headerClass="'fixed-home'"
    :pageDefaultBackLink="'/home'"
    :pageTitle="lang.news_page.title"
    :contentColor="'transparent'"
    :selectLanguage="true"
    @content="setIonContent"
  >
    <template v-slot:actions-end>
      <ion-button @click="store.actions.openSearch('news')">
        <ion-icon slot="icon-only" :icon="search"></ion-icon>
      </ion-button>

      <ion-button router-link="/settings" id="news-settings-btn" v-if="!browser">
        <ion-icon slot="icon-only" :icon="settingsSharp"></ion-icon>
      </ion-button>
    </template>

    <template v-slot:ion-content>
      <div class="curve-bars">
        <div class="light-bar"></div>
        <div class="dark-bar"></div>
      </div>

      <div class="page-container">
        <banner
          v-if="
            initialized &&
            store.state.banners['news_notifications'].status &&
            !store.state.banners['news_notifications'].hide_forever &&
            !browser
          "
          type="warning"
          :actions="banner_actions.notifications_turned_off"
          :title="lang.news_notifications_off ?? 'News notifications off'"
          :text="
            lang.turn_on_news_notifications_text ??
            'Turn on news notifications to receive crypto news updates as soon as they\'re published even when app is closed.'
          "
          name="news_notifications"
        ></banner>

        <!-- App install call to action banner for web only -->
        <download-banner
          v-if="initialized && browser"
          type="info"
          :title="lang.change ?? 'Stay updated on the Uptrade app'"
          :text="
            lang.change ??
            'Receive news notifications as soon as they\'re published, and more when you use our signals via the Uptrade app.'
          "
          name="cta_banner_news"
        ></download-banner>

        <div class="news-scroll parent-scroll ion-padding">
          <news-list
            :loadData="loadNews"
            :actionHandler="listActionHandler"
            :actionTrigger="listActionTrigger"
            newsItemUniqueClass="news-item-news"
            parentElementClass="news-scroll"
            name="news-list"
            ref="newsList"
            @scrolledToTop="listScrolledToTop"
            @initialized="initialized = true"
          >
            <template v-slot:no-result>
              <no-result
                :title="lang.news_page.no_news_found.title"
                :description="lang.news_page.no_news_found.description"
                :actionText="lang.news_page.no_news_found.action"
                :clickHandler="hardReset"
              >
                <template v-slot:image>
                  <img src="../../assets/img/no-news.png" alt="" srcset="" />
                </template>
              </no-result>
            </template>

            <template v-slot:network-error>
              <network-error :clickHandler="hardReset"></network-error>
            </template>
          </news-list>
        </div>

        <transition name="fade">
          <ion-fab
            class="news-ion-fab scroll-up"
            vertical="bottom"
            horizontal="end"
            slot="fixed"
            v-if="newsUpdate"
            v-on:click="softReset"
          >
            <ion-fab-button class="ion-fab-button">
              <ion-icon :icon="arrowUp"></ion-icon>
            </ion-fab-button>
          </ion-fab>
        </transition>
      </div>
      <div id="google_translate_element" style="display: none"></div>
    </template>
  </base-layout-2>
</template>


<script>
import { IonButton, IonIcon, IonFab, IonFabButton } from "@ionic/vue";
import { settingsSharp, search, funnel, arrowUp, cloudDownload } from "ionicons/icons";
import BaseLayout2 from "../layouts/Base2";
import NewsList from "../components/cat-3/NewsList";
import NoResult from "../components/cat-3/NoResult.vue";
import NetworkError from "../components/cat-3/NetworkError.vue";
import Banner from "../components/cat-3/Banner.vue";
import DownloadBanner from '../components/cat-3/DownloadBanner.vue';

export default {
  inject: ["store"],
  props: [
    "pageTitle",
    "noPageHeader",
    "toolbarClass",
    "headerClass",
    "pageDefaultBackLink",
  ],
  data() {
    return {
      settingsSharp,
      search,
      funnel,
      arrowUp,
      signals: [],
      perRequest: 20,
      requests: 0,
      noMoreSignals: false,
      loading: true,
      isCreated: false,
      newsUpdate: false,
      listActionTrigger: false,
      listActionHandler: null,
      content: null,
      initialized: false,
    };
  },
  components: {
    IonButton,
    IonIcon,
    IonFab,
    IonFabButton,
    "base-layout-2": BaseLayout2,
    NewsList,
    NoResult,
    NetworkError,
    Banner,
    DownloadBanner
  },

  methods: {
    async hideNotifBannerForever() {
      let self = this;

      //Hide forever in storage
      await self.store.plugins.Storage.set({
        key: "hide_news_notification_banner_forever",
        value: "true",
      });

      //Hide forever in state
      self.store.setters.setBanner("news_notifications", {
        status: self.store.state.banners["news_notifications"].status,
        hide_forever: true,
      });
    },

    async turnOnNewsNotif() {
      let self = this;

      //Hide banner
      self.store.setters.setBanner("news_notifications", {
        status: false,
        hide_forever:
          self.store.state.banners["news_notifications"].hide_forever,
      });

      try {
        let resp = await window.axios({
          url: self.store.endpoint + "/set-news-notifications",
          method: "post",
          data: {
            status: true,
            firebase_token: self.store.state.firebase_token,
          },
        });

        if (resp.status == 200 && resp.data) {
          let settings = JSON.parse(JSON.stringify(self.store.state.settings));
          settings.notifications.news = true;
          self.store.setters.applySettings(settings);
        }
      } catch (e) {
        console.log(e);
      }
    },

    guide() {
      let self = this;

      self.guideMode(true, self);

      //Set the labels in tour client.
      window.tourClient.setOptions({
        prevLabel: self.lang.back ?? "Back",

        nextLabel: self.lang.next ?? "Next",

        finishLabel: self.lang.got_it ?? "Got it!",
      });

      //Set the steps in tour client
      let steps = [
        {
          content:
            "You can change your news notification preference anytime in settings",

          title: self.lang.settings?.title ?? "Settings",

          target: "#news-settings-btn",
        },
      ];

      window.tourClient.options.steps = steps;

      //When the tour guide is closed at any point
      window.tourClient.onAfterExit(() => {
        self.guideMode(false, self);
      });

      window.tourClient.start();
    },

    setIonContent(content) {
      //Get the page's ion-content element for scroll control
      this.content = content;
    },

    triggerListAction() {
      this.listActionTrigger = !this.listActionTrigger;
    },

    softReset() {
      this.newsUpdate = false;
      this.store.actions.triggerCompAction("news_seen", null);
      this.listActionHandler = (self) => {
        self.softReset();
      };
      this.triggerListAction();
    },

    hardReset() {
      this.listActionHandler = (self) => {
        self.hardReset();
      };
      this.triggerListAction();
    },

    listScrolledToTop() {
      let self = this;
      if (self.newsUpdate) {
        self.listActionHandler = (list) => {
          list.softReset();
          self.newsUpdate = false;
          self.store.actions.triggerCompAction("news_seen", null);
        };
        self.triggerListAction();
      }
    },

    translatePage() {
      const script = document.createElement("script");
      // script.id = scriptID.value;
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://translate.google.com/translate_a/element.js";
      script.onload = () => {
        alert("script loaded");
        window.googleInit("fa", "google_translate_element");
      };

      let techAnalysisView = document.getElementById(
        "google_translate_element"
      );
      techAnalysisView.appendChild(script);
    },

    loadNews(start, size) {
      let self = this;

      let data = {
        start: start,
        slice: size,
      };

      return window.axios({
        url: self.store.endpoint + "/news",
        method: "post",
        data: data,
        headers: {
          Authorization: `Bearer ${self.store.getters.userToken()}`,
        },
      });
    },

    init() {
      if (!this.isCreated) {
        this.$refs.newsList.$refs.recycler.init();
        this.isCreated = true;
        this.store.actions.triggerCompAction("news_seen", null);
        this.store.setters.reloadOnEnter("news", false);
      } else {
        if (this.store.state.reloadOnEnter.news) {
          this.hardReset();
          this.store.actions.triggerCompAction("news_seen", null);
          this.store.setters.reloadOnEnter("news", false);
        }
      }

      // let self = this;

      // self.$nextTick(() => {
      //   //Initialize guide if this is first time viewing spots page
      //   (async () => {
      //     let guideSeen = await self.store.plugins.Storage.get({
      //       key: "news_page_guide",
      //     });

      //     if (!guideSeen.value) {
      //       self.guide();

      //       self.store.plugins.Storage.set({
      //         key: "news_page_guide",
      //         value: "seen",
      //       });
      //     }
      //   })();
      // });
    },
  },

  computed: {
    cta_banner_actions() {
      // let self = this;
      return [
        {
          text: "Download The App",
          icon: cloudDownload,
          handler() {
            alert("opening app");
          },
        },
      ];
    },

    banner_actions() {
      let self = this;
      return {
        notifications_turned_off: [
          {
            text: "Turn on",
            handler() {
              self.turnOnNewsNotif();
              self.guide();
            },
          },

          {
            text: "No thanks",
            class: "outline",
            handler() {
              self.hideNotifBannerForever();
              self.guide();
            },
          },
        ],
      };
    },
    compActionTrigger() {
      return this.store.state.compActionTrigger;
    },
  },

  watch: {
    compActionTrigger() {
      let action = this.store.state.compAction;
      let self = this;

      switch (action.type) {
        case "news_update":
          //If scrolltop is 0, do a soft reset, else just set new signal to true
          self.listActionHandler = (list) => {
            let scroller = document.getElementsByClassName(
              list.parentElementClass
            )[0];
            if (scroller.scrollTop == 0) {
              list.softReset();
            } else {
              this.store.actions.triggerCompAction("news_update_news", null);
              self.newsUpdate = true;
            }
          };
          self.triggerListAction();
          break;

        case "resetNewsList":
          self.listActionHandler = (list) => {
            list.hardReset();
          };
          self.triggerListAction();
          break;
      }
    },
  },

  mounted() {
    // this.translatePage();
  },

  ionViewDidEnter() {
    this.init();
    window.googleTranslateSelectTag.value = this.googleTranslateLocale;
    window.googleTranslateSelectTag.dispatchEvent(new Event("change"));
  },

  created() {},
};
</script>


<style scoped lang="scss">
.page-container {
  // padding-top: 20px;
  border-top-left-radius: 40px;
  background: #fafafc;
  overflow: auto;
  position: relative;
  @include toolbar-content-size-1;

  .news-scroll {
    height: 100%;
    overflow: auto;
  }
}

ion-fab.news-ion-fab {
  position: fixed;
  left: calc(50% - 28px);
  top: 100px;
  width: fit-content;
  height: fit-content;

  ion-fab-button.ion-fab-button {
    --background: #{lighten($primary-color, 70%)};
  }

  ion-icon {
    color: $primary-color;
  }
}
</style>