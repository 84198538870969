<template>
  <infinite-scroll-recycler
    :size="size"
    :loadData="loadData"
    :itemHeight="itemHeight"
    :itemMargin="itemMargin"
    :bgColor="bgColor"
    :itemUniqueClass="newsItemUniqueClass"
    ref="recycler"
  >
    <template v-slot:item="props">
      <news-item
        :data="props.data"
        :key_="props.key_"
        :uniqueClass="newsItemUniqueClass"
      ></news-item>
    </template>

    <template v-slot:tombstone="props">
      <tombstone :loading="props.loading"></tombstone>
    </template>

    <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
      <slot :name="slotName" />
    </template>
  </infinite-scroll-recycler>
</template>

<script>
import InfiniteScrollRecycler from "./InfiniteScrollRecycler.vue";
import Tombstone from "./NewsTombstone";
import NewsItem from "./NewsItem";

export default {
  props: ["loadData", "newsItemUniqueClass"],
  inject: ["store"],
  components: {
    InfiniteScrollRecycler,
    Tombstone,
    NewsItem,
  },

  data() {
    return {
      size: 40, //Whole even number
      itemHeight: 420,
      itemMargin: 25,
      bgColor: "#fafafc",
    };
  },

  method: {},
};
</script>

<style scoped>
</style>