<template>
  <div class="news-item" v-on:click="open(newsItem)">
    <div class="content" ref="content">
      <div
        class="photo-section"
        :style="'height:' + photoHeight + 'px;'"
        ref="photoSection"
      >
        <div
          class="photo"
          :style="'background-image: url(' + placeholderimg + ');'"
        ></div>
      </div>

      <div class="info">
        <p class="title">
          <ion-skeleton-text
            :animated="loading"
            style="width: 100%"
          ></ion-skeleton-text>
          <ion-skeleton-text
            :animated="loading"
            style="width: 100%"
          ></ion-skeleton-text>
          <ion-skeleton-text
            :animated="loading"
            style="width: 80%"
          ></ion-skeleton-text>
        </p>

        <p class="description">
          <ion-skeleton-text
            :animated="loading"
            style="width: 100%"
          ></ion-skeleton-text>
          <ion-skeleton-text
            :animated="loading"
            style="width: 100%"
          ></ion-skeleton-text>
          <ion-skeleton-text
            :animated="loading"
            style="width: 80%"
          ></ion-skeleton-text>
        </p>
      </div>

      <div class="meta">
        <div class="source-img"></div>
        <div class="source-meta">
          <p class="source-name">
            <ion-skeleton-text
              :animated="loading"
              style="width: 100px"
            ></ion-skeleton-text>
          </p>
          <p class="time">
            <ion-skeleton-text
              :animated="loading"
              style="width: 60px"
            ></ion-skeleton-text>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IonSkeletonText } from "@ionic/vue";
import placeholderimg from "../../../assets/img/placeholder-image.jpg";

export default {
  inject: ["store"],
  props: ["newsItem", "loading"],

  components: {
    IonSkeletonText,
  },

  data() {
    return {
      photoHeight: 0,
      placeholderimg,
      // loading: false,
    };
  },

  computed: {
    server() {
      return this.store.server;
    },
  },

  methods: {
    open(newsItem) {
      newsItem;
      //   this.store.setters.signalInView(signal);
      //   this.$router.push("/signal");
    },

    wait(millisecs) {
      return new Promise((resolve) => {
        setTimeout(resolve, millisecs);
      });
    },

    setPhotoHeight(contentWidth) {
      this.photoHeight = (contentWidth / 379) * 214;
    },
  },

  mounted() {
    //Get photo height from client width;
    var contentWidth = this.$refs.content.clientWidth;
    this.setPhotoHeight(contentWidth);

    (async () => {
      while (contentWidth <= 0) {
        contentWidth = this.$refs.content.clientWidth;
        this.setPhotoHeight(contentWidth);
        await this.wait(50);
      }
    })();
  },
};
</script>

<style scoped lang="scss">
.news-item {
  background: #fff;
  --padding-start: 0;
  --inner-padding-end: 0;
  margin-bottom: 25px;
  border-radius: 20px;

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 395px;

    > div {
      width: 100%;
    }

    .photo-section {
      height: 215px;
      border-top-left-radius: 30px;
      border-top-right-radius: 0px;
      display: flex;
      align-items: flex-end;
      position: relative;
      overflow: hidden;
      // box-shadow: 0 -132px 98px -98px #000 inset;

      .photo {
        height: 100%;
        width: 100%;
        background-size: cover;
        border-top-left-radius: 30px;
        position: absolute;
        top: 0;
        left: 0;
        transition: transform 0.2s;
      }
    }

    .info {
      padding: 0 16px;
      color: #000;

      ion-skeleton-text {
        margin-bottom: 7px;
      }

      p.title {
        font-weight: 800;
        margin-bottom: 22px;
      }
      p.description {
        font-weight: 400;
        font-size: calc(13px + $extra-font-size);
      }
    }

    .meta {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 16px;
      margin-bottom: 16px;
      box-sizing: border-box;
      color: #000;

      .source-img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: #eee;
      }

      .source-meta {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .source-name {
          font-weight: 700;
          font-size: calc(13px + $extra-font-size);
          color: var(--ion-color-secondary);
        }

        .time {
          color: var(--ion-color-medium-shade);
          font-size: calc(13px + $extra-font-size);
        }

        p {
          margin: 0;
        }
      }
    }
  }
}

ion-skeleton-text {
  border-radius: 1px;
}
</style>